import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import config from '../package.json'
import 'element-ui/lib/theme-chalk/index.css';
import {mixin} from './utils/mixin.js';
import { baseUrl } from './utils/urls';
import VueClipboard from 'vue-clipboard2'


import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

const elScrollBar = (el) => {
  if (el._ps_ instanceof PerfectScrollbar) {
    el._ps_.update()
  } else {
    el._ps_ = new PerfectScrollbar(el, { suppressScrollX: true })
  }
}

Vue.directive('scrollBar', {
  inserted (el, binding, vnode) {
    const rules = ['fixed', 'absolute', 'relative']
    if (!rules.includes(window.getComputedStyle(el, null).position)) {
      console.error(`perfect-scrollbar所在的容器的position属性必须是以下之一：${rules.join('、')}`)
    }
    elScrollBar(el)
  },
  componentUpdated (el, binding, vnode, oldVnode) {
    try {
      vnode.context.$nextTick(
        () => {
          elScrollBar(el)
        }
      )
    } catch (error) {
      console.error(error)
      elScrollBar(el)
    }
  }
})





Vue.use(VueClipboard)


Vue.mixin(mixin);



Vue.prototype.is_loaded = false;

Vue.use(ElementUI);
Vue.config.productionTip = false

// SEO  优化
router.beforeEach((to, from, next) => {
  /* if (to.path) {
    const pathWithHtml = `${to.path}.html`;
    next({ path: pathWithHtml, query: to.query, replace: true });
  } else {
    next();
  } */
  if (to.meta.title) {
    document.title = to.meta.title
  }else{
    document.title =config.name;
  }
  if(to.meta.content){
    setTimeout(()=>{
      let head = document.getElementsByTagName('head');
      var a=document.getElementById('meta_keywords');
      var b=document.getElementById('meta_description');
      if(a){head[0].removeChild(a);}
      if(b){head[0].removeChild(b);}
      let meta = document.createElement('meta');
      let meta2 = document.createElement('meta');
      meta.setAttribute('name', 'keywords')
      meta.setAttribute('id', 'meta_keywords')
      meta2.setAttribute('name', 'description')
      meta2.setAttribute('id', 'meta_description')
      meta.setAttribute('content', to.meta.content.keywords)
      meta2.setAttribute('content', to.meta.content.description)
      head[0].appendChild(meta);
      head[0].appendChild(meta2); 
    },100)
   
  }else{
 
  }

  next()
})

router.afterEach((to,from,next)=>{
  window.scrollTo(0,0);
})

// 多语言
import i18n from './utils/lang/index';

// 全局socket
import SocketService from '@/utils/socket';
Vue.prototype.$socket = SocketService;




new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
