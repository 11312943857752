import { baseUrl} from './urls.js';
import router from '../router'
import { Message } from 'element-ui';
import { setInterval } from 'core-js';
export const mixin = {
	data() {
		return {
			baseUrl, 
			allow: true,
			scene:0,
			store_id:0,
			wx_code:'',
			fullscreenLoading:false,
			page_loading:true,
			login_status:false,
			site_name:localStorage.getItem('site_name')||'',
			web_icon:localStorage.getItem('web_icon')||'',
			contact_address:localStorage.getItem('contact_address')||'',
			submit_loading:false,
			page_loadings:false
		}
	},
	
	filters: {
		trans_mobile(val) {
			var phone = val.toString(); //获取到的电话信息
			var reg = /(\d{3})\d{4}(\d{4})/; //正则表达式
			phone = phone.replace(reg, "$1****$2");
			return phone;
		},
		pf(val) {
			return parseFloat(val);
		},
		to_arr(val){
			var arr=[];
			arr.push(val);
			return arr;
		},
		fixed(val,num){
			var str=parseFloat(val);
			return str.toFixed(num)
		},
		timeago(dateTimeStamp){   
			var dateTimeStamp = dateTimeStamp.toString();
			var g =/^\d+$/.test(dateTimeStamp)
			if(!g){
				if(dateTimeStamp.search('-')>0){
					dateTimeStamp = new Date(dateTimeStamp.replace(/-/g, '/')).getTime();
				}
			}
			var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
			var hour = minute * 60;
			var day = hour * 24;
			var week = day * 7;
			var halfamonth = day * 15;
			var month = day * 30;
			var now = new Date().getTime(); //获取当前时间毫秒
			var diffValue = now - dateTimeStamp; //时间差
			var result='';
			if(diffValue < 0){
				return;
			}
			var minC = diffValue/minute; //计算时间差的分，时，天，周，月
			var hourC = diffValue/hour;
			var dayC = diffValue/day;
			var weekC = diffValue/week;
			var monthC = diffValue/month;
			if(monthC >= 1 && monthC <= 3){
				result = " " + parseInt(monthC) + "月前"
			}else if(weekC >= 1 && weekC <= 3){
				result = " " + parseInt(weekC) + "周前"
			}else if(dayC >= 1 && dayC <= 6){
				result = " " + parseInt(dayC) + "天前"
			}else if(hourC >= 1 && hourC <= 23){
				result = " " + parseInt(hourC) + "小时前"
			}else if(minC >= 1 && minC <= 59){
				result =" " + parseInt(minC) + "分钟前"
			}else if(diffValue >= 0 && diffValue <= minute){
				result = "刚刚"
			}else {
				var datetime = new Date();
				datetime.setTime(dateTimeStamp);
				var Nyear = datetime.getFullYear();
				var Nmonth = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
				var Ndate = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
				var Nhour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours();
				var Nminute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
				var Nsecond = datetime.getSeconds() < 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
				result = Nyear + "年" + Nmonth + "月" + Ndate + "日" + " " + Nhour + ":" + Nminute
			}
			return result;
		},
		// 自定义时间戳转日期  日期转指定格式   
		// 用法  {{test*1000|time('yyyy-MM-dd hh:mm:ss')}}
		time(val, fmt) {
			fmt = fmt ? fmt : 'yyyy-MM-dd hh:mm:ss';
			if ((val + '').indexOf('-') > -1) {
				val = val.substring(0, 19);
				val = val.replace(/-/g, '/'); //必须把日期'-'转为'/'
				val = new Date(val).getTime();
			} else if ((val + '').indexOf('/') > -1) {
				val = new Date(val).getTime();
			}
			var newDate = new Date(val);
			var o = {
				"M+": newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() +
				1), //月份
				"d+": newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate(), //日
				"h+": newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours(), //小时
				"m+": newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes(), //分
				"s+": newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds(), //秒
				"q+": Math.floor((newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : (newDate
					.getMonth() + 1) + 3) / 3),
				"S": val
			};
			if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (newDate.getFullYear() + "").substr(4 - RegExp.$1
				.length));
			for (var k in o)
				if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[
					k]) : (("00" + o[k]).substr(("" + o[k]).length)));
			return fmt;
		},
		timeFilter(value) {

			var v = value;
			var g = /^\d+$/.test(v)
			if (!g) {

				v = new Date(value.replace(/-/g, '/')).getTime();

			}
			var remain = v - (new Date().getTime());
			// console.log(v,remain)
			if (remain == 0) {
				return ''
			}
			if (remain < 0) {
				remain = remain * (-1)
			};
			var days = Math.floor(remain / (24 * 3600 * 1000));
			var leave1 = remain % (24 * 3600 * 1000);
			var hours = Math.floor(leave1 / (3600 * 1000));
			var leave2 = leave1 % (3600 * 1000);
			var minutes = Math.floor(leave2 / (60 * 1000));
			var leave3 = leave2 % (60 * 1000);
			var seconds = Math.round(leave3 / 1000);
			days = days > 0 ? days + '天' : '';
			hours = hours > 0 ? hours + '时' : '';
			minutes = minutes > 0 ? minutes + '分' : '';
			seconds = seconds > 0 ? seconds + '秒' : '';
			return days + hours;
		},
		time_down(val){
			/* setInterval(()=>{
				val--;
				 // 天
				 var day = parseInt(val / 60 /60 / 24);
				 if (day < 10) {
					 day = "0" + day.toString();
				 }
				 // 时
				 var hour = parseInt(val / 60 /60 % 24);
				 if (hour < 10) {
					 hour = "0" + hour.toString();
				 }
				 // 分
				 var minute = parseInt(val / 60 %60);
				 if (minute < 10) {
					 minute = "0" + minute.toString();
				 }
				 // 秒
				 var second =  parseInt(val % 60);
				 if (second < 10) {
					 second = "0" + second.toString();
				 }
				 //时间
				 var html=day+'天'+hour+'时'+minute+'分'+second+'秒';
				 console.log(html)

				 return html;
			},1000); */
			
		},
		line_break(val){
			var lines = val.split('\n'); // 根据换行符分割文本
			var html = lines.map(line => line.replace(/\n/g, '<br>')).join('<br>');
			var str=html
			.replace(html ? /&(?!#?\w+;)/g : /&/g, '&amp;')
			.replace(/&lt;/g,"<")
			.replace(/&gt;/g,">")
			.replace(/&quot;/g,"\"")
			.replace(/&#39;/g, "\'")
			.replace(/&amp;nbsp;/g,'\u3000');
			console.log(str)
			return str;
		}
	},
	onShow(e) {
		
		
	},
	onLoad() {
	
	},
	methods: {
		to_html(name,id){
			let detailId=id+".html"
			this.$router.push({name:name,params:{id:detailId}})
		},
		// 设置seo优化
		set_seo(d){
			if(d){
				document.title = d.title||'玛雅房产';
				document.querySelector('meta[name="keywords"]').setAttribute('content', d.keyword);
				document.querySelector('meta[name="description"]').setAttribute('content', d.desc);
			}			
		},
		chunkArray(array, chunkSize) {
			let result = [];
			for (let i = 0; i < array.length; i += chunkSize) {
				result.push(array.slice(i, i + chunkSize));
			}
			return result;
		},
		line_trans(val){
			var lines = val.split('\n'); // 根据换行符分割文本
			var html = lines.map(line => line.replace(/\n/g, '<br>')).join('<br>');
			var str=html
			.replace(html ? /&(?!#?\w+;)/g : /&/g, '&amp;')
			.replace(/&lt;/g,"<")
			.replace(/&gt;/g,">")
			.replace(/&quot;/g,"\"")
			.replace(/&#39;/g, "\'")
			.replace(/&amp;nbsp;/g,'\u3000');
			return str;
		},
		arr_sort(arr){
			var interval = parseInt(arr.length / 2);//分组间隔设置
			while(interval > 0){
			for(var i = 0 ; i < arr.length ; i ++){
				var n = i;
				while(arr[n] < arr[n - interval] && n > 0){
					var temp = arr[n];
					arr[n] = arr[n - interval];
					arr[n - interval] = temp;
					n = n - interval;
				}
			}
			interval = parseInt(interval / 2);
			}
			return arr;
		},
		toggleFullScreen(targetElement) {
			const element = document.querySelector('#' + targetElement); // 获取目标元素
			console.log(element)
			if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
			  if (document.exitFullscreen) {
				document.exitFullscreen();
			  } else if (document.webkitExitFullscreen) { /* Safari */
				document.webkitExitFullscreen();
			  } else if (document.mozCancelFullScreen) { /* Firefox */
				document.mozCancelFullScreen();
			  } else if (document.msExitFullscreen) { /* IE/Edge */
				document.msExitFullscreen();
			  }
			} else {
			  if (element.requestFullscreen) {
				element.requestFullscreen();
			  } else if (element.webkitRequestFullscreen) { /* Safari */
				element.webkitRequestFullscreen();
			  } else if (element.mozRequestFullScreen) { /* Firefox */
				element.mozRequestFullScreen();
			  } else if (element.msRequestFullscreen) { /* IE/Edge */
				element.msRequestFullscreen();
			  }
			}
		},
		getRemainTime(value) {
			var v = value;
			var g = /^\d+$/.test(v)
			if (!g) {

				v = new Date(value.replace(/-/g, '/')).getTime();

			}

			var remain = v - (new Date().getTime());

			if (remain <= 0) {
				return this.$t('pub.init_d')
			}

			if (remain < 0) {
				remain = remain * (-1)
			};
			var days = Math.floor(remain / (24 * 3600 * 1000));
			var leave1 = remain % (24 * 3600 * 1000);
			var hours = Math.floor(leave1 / (3600 * 1000));
			var leave2 = leave1 % (3600 * 1000);
			var minutes = Math.floor(leave2 / (60 * 1000));
			var leave3 = leave2 % (60 * 1000);
			var seconds = Math.round(leave3 / 1000);
			days = days > 0 ? days + this.$t('pub.day') : '';
			hours = hours > 0 ? hours + this.$t('pub.hour') : '';
			minutes = minutes > 0 ? minutes + this.$t('pub.minutes') : '';
			seconds = seconds > 0 ? seconds + this.$t('pub.seconds') : '';
			return days + hours + minutes + seconds;
		},
		openFullScreen() {
			this.fullscreenLoading = true;
			setTimeout(() => {
			  this.fullscreenLoading = false;
			}, 2000);
		},
		msg(p) {
			window.location.href = 'sms:' + p
		},
		copy(e) {
			this.$copyText(e).then(res => {
				this.toast(this.$t('pub.copy_s'),1);
			},err => {
				this.toast(`复制失败！`,0);
			});
		},		
		// 跳转页面
		navTo(url) {
			let currentRoute = this.$router.currentRoute;
			if(currentRoute.fullPath==url){
				return ;
			}else{
				/* let detailId=".html";
				console.log(url) */
				// this.$router.push({name:url,params:{id:detailId}})
				router.push({ path:url });
			}
		},
		// 关闭当前页面后跳转
		redTo(url){
			let currentRoute = this.$router.currentRoute;
			if(currentRoute.fullPath==url){
				return ;
			}else{
				router.replace(url);
			}
			
		},
		// 返回上一页
		navBack(d) {
			this.$router.go(-1);
		},
		// 吐丝
		toast(str,type) {
			if(type==0){
				Message.error(str);
			}else if(type==1){
				Message.success(str);
			}else{
				Message.warning(str);
			}
		},
		// 除法精确
		accDiv(arg1,arg2){
			var t1=0,t2=0,r1,r2;
			try{t1=arg1.toString().split(".")[1].length}catch(e){}
			try{t2=arg2.toString().split(".")[1].length}catch(e){}
			r1=Number(arg1.toString().replace(".",""))
			r2=Number(arg2.toString().replace(".",""))
			return Number((r1/r2)*Math.pow(10,t2-t1));
		},
		// 乘法精确
		accMul(arg1, arg2) {
			var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
			try { m += s1.split(".")[1].length } catch (e) { }
			try { m += s2.split(".")[1].length } catch (e) { }
			return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
		},
		// 减法精确
		accSub(a, b) {
			var c, d, e;
			try {
				c = a.toString().split(".")[1].length;
			} catch (f) {
				c = 0;
			}
			try {
				d = b.toString().split(".")[1].length;
			} catch (f) {
				d = 0;
			}
			return e = Math.pow(10, Math.max(c, d)), (this.accMul(a, e) - this.accMul(b, e)) / e;
		},


		params() {
			// 截取url中的code方法
			var url = location.href;
			const reg = /(\w+)=([^&]+)/g;
			const params = {};
			let match;
			
			while ((match = reg.exec(url)) !== null) {
				params[match[1]] = match[2];
			}
			
			return params;
		},
		trans_time(val, fmt) {
			fmt = fmt ? fmt : 'yyyy-MM-dd hh:mm:ss';
			if ((val + '').indexOf('-') > -1) {
				val = val.substring(0, 19);
				val = val.replace(/-/g, '/'); //必须把日期'-'转为'/'
				val = new Date(val).getTime();
			} else if ((val + '').indexOf('/') > -1) {
				val = new Date(val).getTime();
			}
			var newDate = new Date(val);
			var o = {
				"M+": newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() +
				1), //月份
				"d+": newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate(), //日
				"h+": newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours(), //小时
				"m+": newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes(), //分
				"s+": newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds(), //秒
				"q+": Math.floor((newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : (newDate
					.getMonth() + 1) + 3) / 3),
				"S": val
			};
			if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (newDate.getFullYear() + "").substr(4 - RegExp.$1
				.length));
			for (var k in o)
				if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[
					k]) : (("00" + o[k]).substr(("" + o[k]).length)));
			return fmt;
		},
	
	
	}
}
