
// 接口域名封装管理

var url = '';//接口请求域名
if(process.env.NODE_ENV === 'development'){ 
    //开发环境
    url = '/dpi';
}else{
    //正式环境
    url='/';
}

export const baseUrl = url;
export const timeout = 30000;   // 请求超时时间
/* export const ourUrl = 'http://sharestore.ruan.work/'; 
export const uploadUrl = url+'user/user/upload';
export const shareUrl = url+'api/Weixin/getJsSign'; */
