// 中文
export const pub = {
    accounts: "账号",
    password: "密码",
    code: "验证码",
    out:'退出登录',
    confirm_out:'是否立即退出登录',
    tip:'提示',
    confirm:'确定',
    users:'用户端',
    store:'门店端',
    broker:'经纪人端',
    user_center:'个人中心',
    collection:'收藏与对比',
    price_line:'查看区域价格曲线',
    login:'登录',
    register:'注册',
    home:'首页',
    news:'行业新闻',
    auction:'法拍',
    jion:'人才招聘',
    map:'地图找房',
    day:'天',
    hour:'时',
    minutes:'分',
    seconds:'秒',
    copy_s:'复制成功',
    init_d:'0时0分0秒'
}
export const seo={
    t1:"登录",
    t2:"注册",
    t3:"找回密码",
    t4:"修改密码",
    t5:"个人中心",
    t6:"人才招聘",
    t7:"拍卖",
    t8:"行业新闻",
    t9:"房产列表",
    t10:"收藏数据对比",
    t11:"房贷计算",
    t12:"地图找房",
    t13:"价格曲线",
    t14:"收藏与对比",
    t15:"经纪人首页",
    t16:"经纪人登录",
    t17:"经纪人-客户沟通",
    t18:"经纪人-任务提醒",
    t19:"经纪人-行程安排",
    t20:"经纪人-业绩统计",
    t21:"在线联系",
    t22:"经纪人-实时房源详情",
    t23:"经纪人-客户列表",
    t24:"经纪人-客户详情",
}
// broker_header
export const bh={
    out:'退出登录',
    ssfy:'实时房源',
    khgl:'客户管理',
    rwtx:'任务提醒',
    yjtj:'业绩统计',
    jjrxx:'经纪人信息',
}  
// 首页
export const u1={
    search_t:'请输入房源关键字',
    search:'搜索',
    hot_search:'热门搜索',
    t1:'地图查找',
    t2:'地图查找，在地图上搜索房源',
    t3:'查找',
    t4:'房贷计算',
    t5:'房贷计算，在平台上计算买房贷款',
    t6:'去计算',
    t7:'只在一个地方办理所有银行的贷款',
    t8:'房产价格',
    t9:'首付',
    t10:'计算按揭贷款',
    t11:'房产',
    t12:'查看更多',
    t13:'拍卖',
    t14:'当前价',
    t15:'次出价',
    t16:'新闻资讯',
    t17:'查看详情',
    t18:'请选择分类',
    t19:'确定',
    t20:'查看',
}  
export const u2={
    t1:'首页',
    t2:'新闻详情',
    t3:'发布时间',
    t4:'浏览',
}  
export const u3={
    t1:'标的物所在地',
    t2:'起拍价',
    t3:'保底价',
    t4:'加价幅度',
    t5:'人已报名',
    t6:'次围观',
    t7:'交',
    t8:'保证金报名',
    t9:'输入价格',
    t10:'出价',
    t11:'标的物介绍',
    t12:'视频介绍',
    t13:'保证金须知',
    t14:'出价记录',
    t15:'状态',
    t16:'价格',
    t17:'竞拍人',
    t18:'时间',
    t19:'暂无出价记录',
    t20:'请输入正确出价价格',
    t21:'次出价',
}  
export const u4={
    t1:'请输入关键字',
    t2:'竞拍状态',
    t3:'起拍价格',
    t4:'开始时间',
    t5:'结束时间',
    t6:'出价人数',
    t7:'筛选结果',
    t8:'共',
    t9:'数据',
    t10:'当前价',
    t11:'次出价',
    t12:'暂无数据',
    t13:'全部',
    t14:'未开始',
    t15:'进行中',
    t16:'已结束',
}
export const u5={
    t1:'我的收藏',
    t2:'总价',
    t3:'人预览',
    t4:'联系',
    t5:'查看电话号码',
    t6:'收藏',
    t7:'取消收藏',
    t8:'添加对比',
    t9:'暂无数据',
    t10:'电话号码',
    t11:'取 消',
    t12:'确 定',
    t13:'此操作将淘汰该信息, 是否继续',
    t14:'提示',
    t15:'此操作将收藏该信息, 是否继续',
    t16:'此操作将取消收藏该信息, 是否继续',
}
export const u6={
    t1:'总价',
    t2:'照片',
    t3:'介绍',
    t4:'平面图',
    t5:'位置',
    t6:'选择对比',
    t7:'电话号码',
    t8:'预约看房',
    t9:'暂无数据',
    t10:'电话号码',
    t11:'取 消',
    t12:'确 定',
    t13:'选择日期',
    t14:'提示',
    t15:'选择时间',
    t16:'你好，我对这间公寓很感兴趣，想去参观一下。此致！',
    t17:'请选择预约日期',
    t18:'请选择预约时间',
    t19:'咨询内容不能为空',
    t20:'电子邮箱不能为空',
    t21:'联系电话不能为空',
    t22:'联系姓名不能为空',
}
export const u7={
    t1:'找回密码',
    t2:'请输入电子邮箱',
    t3:'验证码',
    t4:'获取验证码',
    t5:'请输入密码',
    t6:'请确认密码',
    t7:'立即找回',
    t8:'返回',
    t9:'邮箱不能为空',
    t10:'密码不能为空',
    t11:'重复密码不能为空',
    t12:'两次输入不一致',
    t13:'验证码不能为空',
}
export const u8={
    t1:'首页',
    t2:'人才招聘',
    t3:'人才招聘详情',
    t4:'招聘条件',
    t5:'职位描述',
    t6:'请输入姓名',
    t7:'请输入联系电话',
    t8:'请输入邮箱',
    t9:'请输入通信地址',
    t10:'请输入说明内容',
    t11:'提交',
    t12:'姓名不能为空',
    t13:'联系电话不能为空',
    t14:'邮箱不能为空',
    t15:'通信地址不能为空',
}
export const u9={
    t1:'首页',
    t2:'人才招聘',
    t3:'立即申请',
}
export const u10={
    t1:'房贷计算',
    t2:'房产价格',
    t3:'首付',
    t4:'贷款年限',
    t5:'年',
    t6:'贷款类型',
    t7:'等额本息',
    t8:'等额本金',
    t9:'固定利率',
    t10:'浮动利率',
    t11:'税收和费用',
    t12:'贷款金额',
    t13:'你的月供',
    t14:'你的月费',
    t15:'贷款金额',
    t16:'融资百分比',
    t17:'查看今日最佳贷款方案',
}
export const u11={
    t1:'请输入邮箱',
    t2:'请输入密码',
    t3:'记住密码',
    t4:'忘记密码',
    t5:'立即登录',
    t6:'登录中',
    t7:'注册',
    t8:'邮箱不能为空',
    t9:'密码不能为空',
}
export const u12={
    t1:'将地图移动到你感兴趣的区域，然后开始勾画',
    t2:'勾画你的区域',
    t3:'重绘',
}
export const u13={
    t1:'首页',
    t2:'个人中心',
    t3:'基本资料',
    t4:'修改登录密码',
    t5:'基本资料',
    t6:'头像',
    t7:'【点击图片更换头像】',
    t8:'昵称',
    t9:'请输入昵称',
    t10:'邮箱',
    t11:'电话',
    t12:'请输入电话号码',
    t13:'修改密码',
    t14:'原密码',
    t15:'请输入原密码',
    t16:'新密码',
    t17:'请输入新密码',
    t18:'确认密码',
    t19:'请确认新密码',
    t20:'保存',
    t21:'上传头像图片大小不能超过 4MB!',
    t22:'原密码不能为空',
    t23:'新密码不能为空',
    t24:'确认密码不能为空',
    t25:'两次输入不一致',
    t26:'用户头像不能为空',
    t27:'用户昵称不能为空',
    t28:'电话不能为空',
    t29:'邮箱不能为空',
}
export const u14={
    t1:'行业新闻',
    t2:'请输入关键字',
    t3:'暂无数据',
}
export const u15={
    t1:'用户注册',
    t2:'请输入电子邮箱',
    t3:'验证码',
    t4:'获取验证码',
    t5:'请输入昵称',
    t6:'请输入密码',
    t7:'请确认密码',
    t8:'注册',
    t9:'已有账号，立即',
    t10:'登录',
    t11:'邮箱不能为空',
    t12:'昵称不能为空',
    t13:'密码不能为空',
    t14:'重复密码不能为空',
    t15:'两次输入不一致',
    t16:'证码不能为空',
}
export const u16={
    t0:'查看地图',
    t1:'总价',
    t2:'人预览',
    t3:'收藏',
    t4:'取消收藏',
    t5:'联系',
    t6:'淘汰',
    t7:'预约看房',
    t8:'视频',
    t9:'照片',
    t10:'介绍',
    t11:'平面图',
    t12:'位置',
    t13:'房贷计算',
    t14:'房产价格',
    t15:'首付',
    t16:'贷款年限',
    t17:'年',
    t18:'贷款类型',
    t19:'等额本息',
    t20:'等额本金',
    t21:'利率',
    t22:'固定利率',
    t23:'浮动利率',   
    t24:'税收和费用',   
    t25:'贷款金额',   
    t26:'你的月供',   
    t27:'咨询/留言',   
    t28:'请输入内容',   
    t29:'你的电子邮件',   
    t30:'请输入电子邮件',   
    t31:'你的电话',   
    t32:'请输入你的电话',   
    t33:'你的姓名',   
    t34:'请输入姓名',   
    t35:'联系',   
    t36:'查看电话号码',   
    t37:'房产中介',   
    t38:'邮箱',   
    t39:'电话号码',   
    t40:'取 消',   
    t41:'确 定',   
    t42:'预约看房',   
    t43:'选择日期',   
    t44:'选择时间',   
    t45:'你好，我对这间公寓很感兴趣，想去参观一下。此致！',   
    t46:'请选择预约日期',   
    t47:'请选择预约时间',   
    t48:'咨询内容不能为空',   
    t49:'电子邮箱不能为空',   
    t50:'联系电话不能为空',   
    t51:'联系姓名不能为空',   
    t52:'此操作将淘汰该信息, 是否继续?',   
    t53:'提示',   
    t54:'此操作将收藏该信息, 是否继续?',   
    t55:'此操作将取消收藏该信息, 是否继续?',   
}
export const u17={
    t1:'请输入房源关键字',
    t2:'条件筛选',
    t3:'价格',
    t4:'下限',
    t5:'上限',
    t6:'面积',
    t7:'住宅类型',
    t8:'居室',
    t9:'卫生间',
    t10:'房屋状态',
    t11:'特征',
    t12:'楼层',
    t13:'土地特征',
    t14:'发布日期',
    t15:'综合排序',
    t16:'价格',
    t17:'发布时间',
    t18:'总价',
    t19:'人预览',
    t20:'联系',
    t21:'查看电话号码',
    t22:'淘汰',
    t23:'收藏',
    t24:'取消收藏',
    t25:'暂无数据',
    t26:'电话号码',
    t27:'取 消',
    t28:'确 定',
    t29:'此操作将淘汰该信息, 是否继续?',
    t30:'提示',
    t31:'此操作将收藏该信息, 是否继续?',
    t32:'此操作将取消收藏该信息, 是否继续?',
    t33:'更多筛选',
    t34:'隐藏筛选',
}

export const b1={
    t1:'基本信息',
    t2:'头像',
    t3:'【点击图片更换头像】',
    t4:'姓名',
    t5:'请输入姓名',
    t6:'昵称',
    t7:'请输入昵称',
    t8:'邮箱',
    t9:'请输入邮箱',
    t10:'电话',
    t11:'请输入电话',
    t12:'保存修改',
    t13:'用户头像不能为空',
    t14:'用户昵称不能为空',
    t15:'电话不能为空',
    t16:'邮箱不能为空',
    t17:'上传头像图片大小不能超过 4MB!',
}
export const b2={
    t1:'请输入',
    t2:'删除',
    t3:'发送',
    t4:'请输入内容',
}
export const b3={
    t1:'请输入',
    t2:'删除',
    t3:'发送',
    t4:'请输入内容',
}
export const b4={
    t1:'联系客户',
    t2:'客户跟进记录',
    t3:'暂无跟进记录',
    t4:'跟踪状态',
    t5:'请选择状态',
    t6:'跟进备注',
    t7:'请输入内容',
    t8:'确认',
    t9:'请选择跟踪状态',
    t10:'备注不能为空',
}
export const b5={
    t1:'请输入客户昵称/电话/邮箱',
    t2:'请选择状态',
    t3:'确定',
    t4:'客户沟通',
    t5:'查看详情',
    t6:'暂无数据',
}
export const b6={
    t1:'查看地图',
    t2:'总价',
    t3:'人预览',
    t4:'视频',
    t5:'照片',
    t6:'介绍',
    t7:'平面图',
    t8:'位置',
    t9:'提单',
    t10:'电子邮件',
    t11:'请输入电子邮件',
    t12:'提单价格',
    t13:'请输入提单价格',
    t14:'备注',
    t15:'请输入备注',
    t16:'确定',
    t17:'房产中介',
    t18:'邮箱',
    t19:'邮箱不能为空',
    t20:'提单价格不能为空',
    t21:'备注不能为空',
}
export const b7={
    t1:'请选择',
    t2:'确认',
    t3:'封面',
    t4:'名称',
    t5:'价格',
    t6:'分类',
    t7:'位置',
    t8:'浏览',
    t9:'状态',
    t10:'时间',
    t11:'操作',
    t12:'详情',
    t13:'提单',
    t14:'你的电子邮件',
    t15:'请输入电子邮件',
    t16:'提单价格',
    t17:'请输入提单价格',
    t18:'备注',
    t19:'请输入内容',
    t20:'取 消',
    t21:'确 定',
    t22:'邮箱不能为空',
    t23:'提单价格不能为空',
    t24:'备注不能为空',   
}
export const b8={
    t1:'经纪人登录',
    t2:'请输入邮箱',
    t3:'请输入登录密码',
    t4:'记住密码',
    t5:'忘记密码',
    t6:'立即登录',
    t7:'找回密码',
    t8:'邮箱',
    t9:'请输入邮箱',
    t10:'验证码',
    t11:'请输入验证码',
    t12:'密码',
    t13:'发送验证码',
    t14:'请输入新密码',
    t15:'立即找回密码',
    t16:'去登录',
    t17:'邮箱不能为空',
    t18:'密码不能为空',
    t19:'验证码不能为空',
}
export const b9={
    t1:'请选择分类',
    t2:'确定',
    t3:'选择月'
}

export const b10={
    t1:'全部',
    t2:'未完成',
    t3:'已完成',
    t4:'完成率',
    t5:'待完成',
    t6:'进行中',
    t7:'已完成',
    t8:'完成任务',
    t9:'暂无数据',
    t10:'是否标记为完成？',
    t11:'提示',
    t12:'确定',
    t13:'取消',
}

