import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



const router = new VueRouter(
  {
    mode: 'history', // 使用HTML5 History模式
    base:'/',
    routes:[
      {
        path: '/',
        name: 'home',
        meta: {
          title: '玛雅房产-首页'
        },
        component:() => import('../views/HomeView.vue')
      },
      {
        path: '/about',
        name: 'about',
        meta: {
          title: '关于我们',
          content:{
            keywords: '',
            description: ''
          }
        },
        component: () => import('../views/AboutView.vue')
      },
      ,{
        path: '/test',
        name: 'test',
        meta: {
          title: '测试',
          content:{
            keywords: '',
            description: ''
          }
        },
        component: () => import('../views/test.vue')
      }
      ,{
        path: '/exam',
        name: 'exam',
        meta: {
          title: '考试',
          content:{
            keywords: '考试,测试',
            description: ''
          }
        },
        component: () => import('../views/exam.vue')
      }
      ,{
        path: '/exam_records',
        name: 'exam_records',
        meta: {
          title: '考试记录',
          content:{
            keywords: '考试记录,测试',
            description: ''
          }
        },
        component: () => import('../views/exam_records.vue')
      }
      ,{
        path: '/question_records',
        name: 'question_records',
        meta: {
          title: '问卷记录',
          content:{
            keywords: '问卷记录,测试',
            description: ''
          }
        },
        component: () => import('../views/question_records.vue')
      }
      ,{
        path: '/login',
        name: 'login',
        meta: {
          title: '登录',
          content:{
            keywords: '登录',
            description: ''
          }
        },
        component: () => import('../views/login.vue')
      }
      ,{
        path: '/register',
        name: 'register',
        meta: {
          title: '注册',
          content:{
            keywords: '注册',
            description: ''
          }
        },
        component: () => import('../views/register.vue')
      }
      ,{
        path: '/find_pwd',
        name: 'find_pwd',
        meta: {
          title: '找回密码',
          content:{
            keywords: '找回密码',
            description: ''
          }
        },
        component: () => import('../views/find_pwd.vue')
      }
      ,{
        path: '/pwd',
        name: 'pwd',
        meta: {
          title: '修改密码',
          content:{
            keywords: '修改密码',
            description: ''
          }
        },
        component: () => import('../views/pwd.vue')
      }
      ,{
        path: '/my',
        name: 'my',
        meta: {
          title: '个人中心',
          content:{
            keywords: '个人中心',
            description: ''
          }
        },
        component: () => import('../views/my.vue')
      }
      ,{
        path: '/questionnaire',
        name: 'questionnaire',
        meta: {
          title: '问卷调查',
          content:{
            keywords: '问卷调查',
            description: ''
          }
        },
        component: () => import('../views/questionnaire.vue')
      }
      ,{
        path: '/analysis',
        name: 'analysis',
        meta: {
          title: '答案解析',
          content:{
            keywords: '答案解析',
            description: ''
          }
        },
        component: () => import('../views/analysis.vue')
      }
      ,{
        path: '/question_log_detail',
        name: 'question_log_detail',
        meta: {
          title: '问卷详情',
          content:{
            keywords: '问卷详情',
            description: ''
          }
        },
        component: () => import('../views/question_log_detail.vue')
      }
      ,{
        path: '/list',
        name: 'list',
        meta: {
          title: '测试列表',
          content:{
            keywords: '测试列表',
            description: ''
          }
        },
        component: () => import('../views/list.vue')
      }
      ,{
        path: '/art_detail/:id',
        name: 'art_detail',
        meta: {
          title: '文章详情',
          content:{
            keywords: '文章详情',
            description: ''
          }
        },
        component: () => import('../views/art_detail.vue')
      }
      ,{
        path: '/jion',
        name: 'jion',
        meta: {
          title: '人才招聘',
          content:{
            keywords: '人才招聘',
            description: ''
          }
        },
        component: () => import('../views/jion.vue')
      }
      ,{
        path: '/jion_detail/:id',
        name: 'jion_detail',
        meta: {
          title: '人才招聘详情',
          content:{
            keywords: '人才招聘详情',
            description: ''
          }
        },
        component: () => import('../views/jion_detail.vue')
      }
      ,{
        path: '/auction',
        name: 'auction',
        meta: {
          title: '拍卖',
          content:{
            keywords: '拍卖',
            description: ''
          }
        },
        component: () => import('../views/auction.vue')
      }
      ,{
        path: '/auction_list',
        name: 'auction_list',
        meta: {
          title: '拍卖列表',
          content:{
            keywords: '拍卖列表',
            description: ''
          }
        },
        component: () => import('../views/auction_list.vue')
      }
      ,{
        path: '/auction_detail/:id',
        name: 'auction_detail',
        meta: {
          title: '拍卖详情',
          content:{
            keywords: '拍卖详情',
            description: ''
          }
        },
        component: () => import('../views/auction_detail.vue')
      }
      ,{
        path: '/news',
        name: 'news',
        meta: {
          title: '行业新闻',
          content:{
            keywords: '行业新闻',
            description: ''
          }
        },
        component: () => import('../views/news.vue')
      }
      ,{
        path: '/search',
        name: 'search',
        meta: {
          title: '房产列表',
          content:{
            keywords: '房产列表',
            description: ''
          }
        },
        component: () => import('../views/search.vue')
      }
      ,{
        path: '/search_detail/:id',
        name: 'search_detail',
        meta: {
          title: '房产详情',
          content:{
            keywords: '房产详情',
            description: ''
          }
        },
        component: () => import('../views/search_detail.vue')
      }
      ,{
        path: '/contrast',
        name: 'contrast',
        meta: {
          title: '收藏数据对比',
          content:{
            keywords: '收藏数据对比',
            description: ''
          }
        },
        component: () => import('../views/contrast.vue')
      }
      ,{
        path: '/loan',
        name: 'loan',
        meta: {
          title: '房贷计算',
          content:{
            keywords: '房贷计算',
            description: ''
          }
        },
        component: () => import('../views/loan.vue')
      }
      ,{
        path: '/map',
        name: 'map',
        meta: {
          title: '地图找房',
          content:{
            keywords: '地图找房',
            description: ''
          }
        },
        component: () => import('../views/map.vue')
      }
      ,{
        path: '/line',
        name: 'line',
        meta: {
          title: '价格曲线',
          content:{
            keywords: '价格曲线',
            description: ''
          }
        },
        component: () => import('../views/line.vue')
      }
      ,{
        path: '/collection',
        name: 'collection',
        meta: {
          title: '收藏与对比',
          content:{
            keywords: '收藏与对比',
            description: ''
          }
        },
        component: () => import('../views/collection.vue')
      }
      ,{
        path: '/broker/index',
        name: 'broker_index',
        meta: {
          title: '经纪人首页',
          content:{
            keywords: '经纪人首页',
            description: ''
          }
        },
        component: () => import('../views/broker/index.vue')
      }
      ,{
        path: '/broker/login',
        name: 'broker_login',
        meta: {
          title: '经纪人登录',
          content:{
            keywords: '经纪人登录',
            description: ''
          }
        },
        component: () => import('../views/broker/login.vue')
      }
      ,{
        path: '/broker/communicate',
        name: 'communicate',
        meta: {
          title: '经纪人-客户沟通',
          content:{
            keywords: '经纪人-客户沟通',
            description: ''
          }
        },
        component: () => import('../views/broker/communicate.vue')
      }
      ,{
        path: '/broker/task',
        name: 'task',
        meta: {
          title: '经纪人-任务提醒',
          content:{
            keywords: '经纪人-任务提醒',
            description: ''
          }
        },
        component: () => import('../views/broker/task.vue')
      }
      ,{
        path: '/broker/plan',
        name: 'plan',
        meta: {
          title: '经纪人-行程安排',
          content:{
            keywords: '经纪人-行程安排',
            description: ''
          }
        },
        component: () => import('../views/broker/plan.vue')
      }
      ,{
        path: '/broker/statistics',
        name: 'statistics',
        meta: {
          title: '经纪人-业绩统计',
          content:{
            keywords: '经纪人-业绩统计',
            description: ''
          }
        },
        component: () => import('../views/broker/statistics.vue')
      }
      ,{
        path: '/broker/broker_info',
        name: 'broker_info',
        meta: {
          title: '经纪人-经纪人信息',
          content:{
            keywords: '经纪人-经纪人信息',
            description: ''
          }
        },
        component: () => import('../views/broker/broker_info.vue')
      }
      ,{
        path: '/chat',
        name: 'chat',
        meta: {
          title: '在线联系',
          content:{
            keywords: '在线联系',
            description: ''
          }
        },
        component: () => import('../views/chat.vue')
      }
      ,{
        path: '/broker/index_detail',
        name: 'index_detail',
        meta: {
          title: '经纪人-实时房源详情',
          content:{
            keywords: '经纪人-实时房源详情',
            description: ''
          }
        },
        component: () => import('../views/broker/index_detail.vue')
      }
      ,{
        path: '/broker/customer',
        name: 'customer',
        meta: {
          title: '经纪人-客户列表',
          content:{
            keywords: '经纪人-客户列表',
            description: ''
          }
        },
        component: () => import('../views/broker/customer.vue')
      }
      ,{
        path: '/broker/customer_detail',
        name: 'customer_detail',
        meta: {
          title: '经纪人-客户详情',
          content:{
            keywords: '经纪人-客户详情',
            description: ''
          }
        },
        component: () => import('../views/broker/customer_detail.vue')
      }
    ]
  }
)



export default router
