import Vue from "vue";
// import VueI18n from "vue-i18n";
import VueI18n from 'vue-i18n';
// import VueI18n from '../../node_modules/vue-i18n/index.js';
 
Vue.use(VueI18n); // 全局挂载
//  console.log(localStorage.getItem("lang"))



export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "spain", // 从localStorage中获取 默认英文
  messages: {
    'zh-cn': require("./lan/zh"), // 中文语言包
    'en-us': require("./lan/en"), // 英文语言包
    'spain': require("./lan/es") // 英文语言包
  }
});
 
export default i18n;