import http from '../utils/request/http'

export function index_home(params){
    return http.get('user/index/index',params)
}
export function index_top(params){
    return http.get('user/index/top',params)
}
export function index_bottom(params){
    return http.get('user/index/bottom',params)
}
export function set_lang(params){
    return http.get('user/index/set_lang',params)
}
export function user_login(params){
    return http.post('user/common/login',params)
}
export function get_line(params){
    return http.post('user/index/get_line',params)
}
export function get_line_get(params){
    return http.get('user/index/get_line',params)
}
export function recruit_list(params){
    return http.get('user/index/recruit_list',params)
}
export function recruit_info(params){
    return http.get('user/index/recruit_info',params)
}
export function sub_recruit(params){
    return http.get('user/user/sub_recruit',params)
}
export function article_list(params){
    return http.get('user/index/article_list',params)
}
export function article_info(params){
    return http.get('user/index/article_info',params)
}
export function auction_list(params){
    return http.get('user/Auction/list',params)
}
export function auction_info(params){
    return http.get('user/Auction/auction_info',params)
}
export function log_list(params){
    return http.get('user/Auction/log_list',params)
}
export function auction_offer_log(params){
    return http.get('user/Auction/auction_offer_log',params)
}
export function auction_apply(params){
    return http.get('user/Auction/auction_apply',params)
}
export function house_index(params){
    return http.get('user/house/index',params)
}
export function get_screen_list(params){
    return http.get('user/house/get_screen_list',params)
}
export function collect_house(params){
    return http.get('user/house/collect_house',params)
}
export function house_blacklist(params){
    return http.get('user/house/house_blacklist',params)
}
export function contact(params){
    return http.get('user/house/contact',params)
}
export function house_info(params){
    return http.get('user/house/house_info',params)
}
export function member_comment(params){
    return http.post('user/user/member_comment',params)
}
export function reservation(params){
    return http.post('user/house/reservation',params)
}
export function collect_list(params){
    return http.get('user/user/collect_list',params)
}
export function select_house(params){
    return http.get('user/user/select_house',params)
}
export function user_index(params){
    return http.get('user/user/index',params)
}
export function update_user_info(params){
    return http.post('user/user/update_user_info',params)
}
export function change_password(params){
    return http.post('user/user/change_password',params)
}
export function logout(params){
    return http.get('user/common/logout',params)
}
export function register(params){
    return http.get('user/common/register',params)
}
export function get_email_code(params){
    return http.get('user/common/get_email_code',params)
}
export function forgot_password(params){
    return http.get('user/common/forgot_password',params)
}


// 经纪人端


export function is_broker(params){
    return http.get('brokers/index/is_broker',params)
}
export function broker_login(params){
    return http.get('brokers/common/login',params)
}
export function get_email_codes(params){
    return http.get('brokers/common/get_email_code',params)
}
export function broker_house_index(params){
    return http.get('brokers/house/index',params)
}
export function set_end(params){
    return http.post('brokers/house/set_end',params)
}
export function broker_logout(params){
    return http.get('brokers/user/logout',params)
}
export function broker_house_info(params){
    return http.get('brokers/house/house_info',params)
}
export function customer_index(params){
    return http.get('brokers/customer/index',params)
}
export function add_clue_post(params){
    return http.post('brokers/customer/add_clue',params)
}
export function add_clue(params){
    return http.get('brokers/customer/add_clue',params)
}
export function task_list(params){
    return http.get('brokers/customer/task_list',params)
}
export function task_done(params){
    return http.get('brokers/customer/task_done',params)
}
export function broker_update_user_info(params){
    return http.post('brokers/user/update_user_info',params)
}
export function broker_info(params){
    return http.get('brokers/user/index',params)
}
export function broker_get_line_post(params){
    return http.post('brokers/house/get_line',params)
}
export function broker_get_line(params){
    return http.get('brokers/house/get_line',params)
}
export function get_broker_sales(params){
    return http.get('brokers/house/get_broker_sales',params)
}
export function loan(params){
    return http.post('user/index/loan',params)
}

// 地图找房

export function get_insert_area(params){
    return http.get('user/house/insert_area',params)
}
export function post_insert_area(params){
    return http.post('user/house/insert_area',params)
}

export function msg_info(params){
    return http.get('user/house/msg_info',params)
}

// 聊天
export function msg_list(params){
    return http.get('brokers/customer/msg_list',params)
}
export function broker_msg_info(params){
    return http.get('brokers/customer/msg_info',params)
}
