// 英语
export const pub = {
  accounts: "Account",
  password: "Password",
  code: "Verification Code",
  out:'Sign Out',
  confirm_out:'Do you want to log out immediately',
  tip:'Tips',
  confirm:'confirm',
  users:'USER',
  store:'Store',
  broker:'Broker',
  user_center:'Personal Center',
  collection:'Collection and Comparison',
  price_line:'View regional price curve',
  login:'login',
  register:'Register',
  home:'Home',
  news:'News',
  auction:'Auction',
  jion:'Recruit',
  map:'Map',
  day:'Day',
  hour:'H',
  minutes:'M',
  seconds:'S',
  copy_s:'Successful replication',
  init_d:'0H0M0S'
}
export const seo={
  t1:"Login",
  t2:"Regiser",
  t3:"Find password",
  t4:"Change password",
  t5:"Individual center",
  t6:"Talent recruitment",
  t7:"Auction",
  t8:"Industry news",
  t9:"Property list",
  t10:"Collection data comparison",
  t11:"Mortgage calculation",
  t12:"Map search",
  t13:"Price curve",
  t14:"Collection and comparison",
  t15:"Broker homepage",
  t16:"Broker login",
  t17:"Broker-client communication",
  t18:"Agent - Task alert",
  t19:"Broker - Itinerary",
  t20:"Brokers - Performance statistics",
  t21:"Online contact",
  t22:"Broker - Real time listing details",
  t23:"Broker - Client list",
  t24:"Broker - Client details",
}
// broker_header
export const bh={
  out:'Sign Out',
  ssfy:'Real-time listing',
  khgl:'Customer',
  rwtx:'Task reminder',
  yjtj:'Performance',
  jjrxx:'Broker',
}  
// Home
export const u1={
  search_t:'Please enter the keyword of the property',
  search:'Search',
  hot_search:'Hot search',
  t1:'Map search',
  t2:'Map Search, search for listings on a map',
  t3:'Look up',
  t4:'Mortgage calculation',
  t5:'Mortgage calculation, calculate the house loan on the platform',
  t6:'Go to calculate',
  t7:'Get all your bank loans in one place',
  t8:'House price',
  t9:'Down payment',
  t10:'Calculate mortgage loan',
  t11:'House property',
  t12:'See more',
  t13:'Auction',
  t14:'Current price',
  t15:'sub-bid',
  t16:'News information',
  t17:'View details',
  t18:'Please select category',
  t19:'OK',
  t20:'Book Now',
}  
export const u2={
  t1:'Home',
  t2:'News details',
  t3:'Release time',
  t4:'Browse',
}  
export const u3={
  t1:'Location of the subject matter',
  t2:'Starting price',
  t3:'Floor price',
  t4:'Margin of markup',
  t5:'Already registered',
  t6:'onlookers',
  t7:'Hand in',
  t8:'Deposit registration',
  t9:'Input price',
  t10:'bid',
  t11:'Introduction to the subject matter',
  t12:'Video introduction',
  t13:'Margin instruction',
  t14:'Bid record',
  t15:'status',
  t16:'Price',
  t17:'bidder',
  t18:'time',
  t19:'No bid has been recorded',
  t20:'Please enter the correct bid price',
  t21:'Bid frequency',
}  
export const u4={
  t1:'Please enter a keyword',
  t2:'Auction status',
  t3:'Starting price',
  t4:'Start time',
  t5:'End time',
  t6:'Number of bidders',
  t7:'Screening result',
  t8:'Total',
  t9:'data',
  t10:'Current price',
  t11:'Bid frequency',
  t12:'No data available',
  t13:'All',
  t14:'Not yet started',
  t15:'Under way',
  t16:'Already ended',
}
export const u5={
  t1:'My collection',
  t2:'Total price',
  t3:'Preview',
  t4:'connection',
  t5:'View phone numbers',
  t6:'Collect',
  t7:'uncollect',
  t8:'Add contrast',
  t9:'No data available',
  t10:'Telephone number',
  t11:'Cancel',
  t12:'Confirm',
  t13:'This operation will eliminate the information, whether to continue',
  t14:'Tips',
  t15:'This operation will save the information, whether to continue',
  t16:'This operation will cancel the collection of the information, whether to continue',
}
export const u6={
  t1:'Total price',
  t2:'Photograph',
  t3:'Introduce',
  t4:'Plane graph',
  t5:'Position',
  t6:'Selective contrast',
  t7:'Telephone number',
  t8:'Make an appointment to see the house',
  t9:'No data available',
  t10:'Telephone number',
  t11:'Cancel',
  t12:'Confirm',
  t13:'Select date',
  t14:'Tips',
  t15:'Selection time',
  t16:"Hi, I'm interested in this apartment and would like to visit it. With regards",
  t17:'Please select an appointment date',
  t18:'Please select an appointment time',
  t19:'The consultation content cannot be empty',
  t20:'Email cannot be empty',
  t21:'The contact number cannot be empty',
  t22:'The contact name cannot be empty',
}
export const u7={
  t1:'Retrieve password',
  t2:'Please enter your email address',
  t3:'Verification code',
  t4:'Get code',
  t5:'Please enter password',
  t6:'Please confirm the password',
  t7:'Immediate retrieval',
  t8:'Back',
  t9:'The mailbox cannot be empty',
  t10:'The password cannot be empty',
  t11:'The duplicate password cannot be empty',
  t12:'The two inputs are inconsistent',
  t13:'The verification code cannot be empty',
}
export const u8={
  t1:'Home',
  t2:'Talent recruitment',
  t3:'Recruitment Details',
  t4:'Recruitment condition',
  t5:'Job description',
  t6:'Please enter your name',
  t7:'Please enter a contact number',
  t8:'Please enter email',
  t9:'Please enter the mailing address',
  t10:'Please enter a description',
  t11:'submit',
  t12:'The name cannot be blank',
  t13:'The contact number cannot be empty',
  t14:'The mailbox cannot be empty',
  t15:'The communication address cannot be empty',
}
export const u9={
  t1:'Home',
  t2:'Talent recruitment',
  t3:'Apply now',
}
export const u10={
  t1:'Mortgage calculation',
  t2:'House price',
  t3:'Down payment',
  t4:'Loan life',
  t5:'years',
  t6:'Purchase type',
  t7:'Equal principal and interest',
  t8:'Equivalent principal',
  t9:'Fixed interest rate',
  t10:'Floating rate',
  t11:'Taxes and fees',
  t12:'Loan amount',
  t13:'Your monthly payment',
  t14:'Your monthly fee',
  t15:'Loan amount',
  t16:'Percentage of financing',
  t17:"Check out today's best Loans",
}
export const u11={
  t1:'Please enter email',
  t2:'Please enter password',
  t3:'Remember the password',
  t4:'Forgot password',
  t5:'Sign in now',
  t6:'Be logging in',
  t7:'Sign in',
  t8:'The mailbox cannot be empty',
  t9:'The password cannot be empty',
}
export const u12={
  t1:"Move the map to the area you're interested in and start sketching",
  t2:'Outline your area',
  t3:'Redraw',
}
export const u13={
  t1:'Home',
  t2:'Individual center',
  t3:'Basic data',
  t4:'Changing the Login Password',
  t5:'Basic data',
  t6:'Avatar',
  t7:'【Click on the picture to change your avatar】',
  t8:'Nickname',
  t9:'Please enter a nickname',
  t10:'Your Email',
  t11:'Telephone',
  t12:'Please enter Telephone number',
  t13:'Change password',
  t14:'Old password',
  t15:'Please enter the old password',
  t16:'New password',
  t17:'Please enter your new password',
  t18:'Confirm password',
  t19:'Please confirm the new password',
  t20:'Save',
  t21:'The size of the uploaded profile picture cannot exceed 4MB!',
  t22:'The old password cannot be empty',
  t23:'The new password cannot be empty',
  t24:'Confirm that the password cannot be empty',
  t25:'The two inputs are inconsistent',
  t26:'The user profile picture cannot be empty',
  t27:'The user nickname cannot be empty',
  t28:'The phone cannot be empty',
  t29:'The mailbox cannot be empty',
}
export const u14={
  t1:'Industry news',
  t2:'Please enter a keyword',
  t3:'No data available',
}
export const u15={
  t1:'User registration',
  t2:'Please enter your email address',
  t3:'Verification code',
  t4:'Get code',
  t5:'Please enter a nickname',
  t6:'Please enter password',
  t7:'Please confirm the password',
  t8:'Register',
  t9:'Existing account，Now',
  t10:'Log in',
  t11:'The mailbox cannot be empty',
  t12:'Nicknames cannot be empty',
  t13:'The password cannot be empty',
  t14:'The duplicate password cannot be empty',
  t15:'The two inputs are inconsistent',
  t16:'The verification code cannot be empty',
}
export const u16={
  t0:'View map',
  t1:'Total price',
  t2:'Preview',
  t3:'Collect',
  t4:'uncollect',
  t5:'Connection',
  t6:'Die out',
  t7:'Make an appointment to see the house',
  t8:'Video',
  t9:'Photograph',
  t10:'Introduce',
  t11:'Plane graph',
  t12:'Position',
  t13:'Mortgage calculation',
  t14:'House price',
  t15:'Down payment',
  t16:'Loan life',
  t17:'years',
  t18:'Purchase type',
  t19:'Equal principal and interest',
  t20:'Equivalent principal',
  t21:'Interest rate',
  t22:'Fixed interest rate',
  t23:'Floating rate',   
  t24:'Taxes and fees',   
  t25:'Loan amount',   
  t26:'Your monthly payment',   
  t27:'Consultation/Message',   
  t28:'Please enter content',   
  t29:'Your email',   
  t30:'Please enter your email',   
  t31:'Your phone',   
  t32:'Please enter your phone number',   
  t33:'Your name',   
  t34:'Please enter your name',   
  t35:'connection',   
  t36:'View Telephone number',   
  t37:'Real estate agency',   
  t38:'Email',   
  t39:'Telephone number',   
  t40:'Cancel',   
  t41:'Confirm',   
  t42:'Make an appointment to see the house',   
  t43:'Select date',   
  t44:'Selection time',   
  t45:"Hi, I'm interested in this apartment and would like to visit it. With regards",   
  t46:'Please select an appointment date',   
  t47:'Please select an appointment time',   
  t48:'The consultation content cannot be empty',   
  t49:'Email cannot be empty',   
  t50:'The contact number cannot be empty',   
  t51:'Connection The name cannot be blank',   
  t52:'This operation will eliminate the information, whether to continue?',   
  t53:'Tips',   
  t54:'This operation will save the information, whether to continue?',   
  t55:'This operation will cancel the collection of the information, whether to continue?',   
}
export const u17={
  t1:'Please enter the keyword of the property',
  t2:'Conditional screening',
  t3:'Price',
  t4:'Floor limit',
  t5:'Upper limit',
  t6:'Area',
  t7:'Housing type',
  t8:'Living room',
  t9:'Rest room',
  t10:'Building condition',
  t11:'Trait',
  t12:'Floor',
  t13:'Land characteristics',
  t14:'Release date',
  t15:'Synthetic sort',
  t16:'Price',
  t17:'Release time',
  t18:'Total price',
  t19:'Preview',
  t20:'Connection',
  t21:'View Telephone number',
  t22:'Die out',
  t23:'Collect',
  t24:'Uncollect',
  t25:'No data available',
  t26:'Telephone number',
  t27:'Cancel',
  t28:'Confirm',
  t29:'This operation will eliminate the information, whether to continue?',
  t30:'Tips',
  t31:'This operation will save the information, whether to continue?',
  t32:'This operation will cancel the collection of the information, whether to continue?',
  t33:'More screening',
  t34:'Hidden filtering',
}

export const b1={
  t1:'Basic information',
  t2:'Avatar',
  t3:'【Click on the picture to change your avatar】',
  t4:'Name',
  t5:'Please enter your name',
  t6:'Nickname',
  t7:'Please enter a nickname',
  t8:'Email',
  t9:'Please enter email',
  t10:'Telephone',
  t11:'Please enter phone number',
  t12:'Save changes',
  t13:'The user profile picture cannot be empty',
  t14:'The user nickname cannot be empty',
  t15:'The phone cannot be empty',
  t16:'The mailbox cannot be empty',
  t17:'The size of the uploaded profile picture cannot exceed 4MB!',
}
export const b2={
  t1:'Please enter',
  t2:'Delete',
  t3:'Send',
  t4:'Please enter content',
}
export const b3={
  t1:'Please enter',
  t2:'Delete',
  t3:'Send',
  t4:'Please enter content',
}
export const b4={
  t1:'Contact customer',
  t2:'Customer follow-up record',
  t3:'No follow-up records',
  t4:'Tracking state',
  t5:'Please select status',
  t6:'Follow-up remarks',
  t7:'Please enter content',
  t8:'Confirm',
  t9:'Please select the tracking status',
  t10:'The remarks cannot be empty',
}
export const b5={
  t1:'Please enter customer nickname/phone number/email address',
  t2:'Please select status',
  t3:'OK',
  t4:'Customer communication',
  t5:'View details',
  t6:'No data available',
}
export const b6={
  t1:'View map',
  t2:'Total price',
  t3:'Preview',
  t4:'Video',
  t5:'Photograph',
  t6:'Introduce',
  t7:'Plane graph',
  t8:'Position',
  t9:'Bill of lading',
  t10:'Electronic mail',
  t11:'Please enter your email',
  t12:'Bill of lading price',
  t13:'Please enter bill of lading price',
  t14:'Remarks',
  t15:'Please enter remarks',
  t16:'OK',
  t17:'Real estate agency',
  t18:'Email',
  t19:'The mailbox cannot be empty',
  t20:'The bill of lading price cannot be blank',
  t21:'The remarks cannot be empty',
}
export const b7={
  t1:'Please select',
  t2:'Confirm',
  t3:'Cover',
  t4:'Name',
  t5:'Price',
  t6:'Classification',
  t7:'Position',
  t8:'Views',
  t9:'Status',
  t10:'Time',
  t11:'Controls',
  t12:'Detail',
  t13:'Bill of lading',
  t14:'Your email',
  t15:'Please enter your email',
  t16:'Bill of lading price',
  t17:'Please enter bill of lading price',
  t18:'Remarks',
  t19:'Please enter content',
  t20:'Cancel',
  t21:'Confirm',
  t22:'The mailbox cannot be empty',
  t23:'The bill of lading price cannot be blank',
  t24:'The remarks cannot be empty',   
}
export const b8={
  t1:'Broker login',
  t2:'Please enter email',
  t3:'Please enter your login password',
  t4:'Remember the password',
  t5:'Forgot password',
  t6:'Sign in now',
  t7:'Retrieve password',
  t8:'Email',
  t9:'Please enter email',
  t10:'Verification code',
  t11:'Please enter the verification code',
  t12:'Password',
  t13:'Send verification code',
  t14:'Please enter your new password',
  t15:'Retrieve password now',
  t16:'Go and log in',
  t17:'The mailbox cannot be empty',
  t18:'The password cannot be empty',
  t19:'The verification code cannot be empty',
}
export const b9={
  t1:'Please select category',
  t2:'OK',
  t3:'Select month'
}
export const b10={
  t1:'All',
  t2:'Uncompleted',
  t3:'Completed',
  t4:'Rate of completion',
  t5:'To be completed',
  t6:'Under way',
  t7:'Completed',
  t8:'Complete the task',
  t9:'No data available',
  t10:'Is it marked as completed?',
  t11:'Tips',
  t12:'OK',
  t13:'Cancel',
}

