import { Message } from 'element-ui'

class WebSocketService {
    constructor() {
        this.webSockets = {} // 存储WebSocket实例
    }
    /**
     * 初始化websocket
     * @param {*} url socket地址
     * @param {*} callback 回调函数
     */

    // 初始化websocket
    initWebSocket(url, callback) {
        if(url){
            const websocketUrl = url.replace('http://', 'ws://')
            if (callback && typeof callback !== 'function') {
                throw new Error('callback is not a function')
            }
            if ('WebSocket' in window) {
                const socket = new WebSocket(websocketUrl)
                socket.onopen = () => this.webSocketOpen(socket)
                socket.onmessage = (event) => this.webSocketOnMessage(event, socket)
                socket.onclose = (event) => this.webSocketOnClose(event, socket)
                socket.onerror = (error) => this.webSocketOnError(error, socket)
                

                this.webSockets[url] = {
                    socket,
                    callback,
                    isConnect: true, // socket连接状态
                    heartbeatTime: 60 * 1000 // 心跳监测时间
                }
            } else {
                return Message.error('该浏览器不支持 WebSocket!')
            }
        }
    }

  webSocketOpen = (socket) => {
      console.log('WebSocket连接成功')
      console.log(this.webSockets)
      const entry = this.findEntryBySocket(socket)
      if (entry) {
          this.startHeartbeat(socket, entry.heartbeatTime)
          entry.reConnectNum = 0
      }
  }

  webSocketOnMessage = (event, socket) => {
      //   console.log('WebSocket接收到消息:', event.data)
      const entry = this.findEntryBySocket(socket)
      if (entry) {
          const { callback } = entry
          const data = JSON.parse(event.data)
          callback({ res: data, isConnect: entry.isConnect })
      }
  }

  webSocketOnClose = (event, socket) => {
      const entry = this.findEntryBySocket(socket)
      if (entry) {
          this.stopHeartbeat(socket)
          entry.isConnect = false
          const { callback } = entry
          callback({ isConnect: entry.isConnect })
          //   console.log(`WebSocket已关闭 (code:${event.code})`)
          if (event.code === 1006) {
              if (entry.reConnectNum < 3) {
                  this.initWebSocket(entry.url, entry.callback)
                  entry.reConnectNum++
              } else {
                  Message.error('WebSocket连接不上，请刷新页面或联系开发人员!')
              }
          }
      }
  }

  webSocketOnError = (error, socket) => {
      const entry = this.findEntryBySocket(socket)
      if (entry) {
          this.stopHeartbeat(socket)
          entry.isConnect = false
          const { callback } = entry
          callback({ isConnect: entry.isConnect })
          console.error('WebSocket连接发生错误:', error)
      }
  }

  webSocketSend = (socket, data,url) => {
        socket=socket||this.webSockets[url].socket
   
      if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify(data))
      } else {
          console.error('WebSocket连接未打开')
      }
  }

  startHeartbeat = (socket, heartbeatTime) => {
      socket.heartbeatTimer = setInterval(() => {
          this.webSocketSend(socket, { heartbeat: true })
      }, heartbeatTime)
  }

  stopHeartbeat = (socket) => {
      clearInterval(socket.heartbeatTimer)
  }

  closeWebSocket = (url) => {
      console.log('WebSocket连接关闭')
      const entry = this.webSockets[url]
      if (entry) {
          const { socket } = entry
          socket.close()
          delete this.webSockets[url] // 从存储中移除WebSocket实例
          this.stopHeartbeat(socket)
          entry.reConnectNum = 0
      }
  }
  closeAllWebSockets = () => {
      console.log('关闭所有WebSocket连接')
      Object.values(this.webSockets).forEach((entry) => {
          const { socket } = entry
          socket.close()
      })
      this.webSockets = {} // 清空存储的WebSocket实例
  }
  findEntryBySocket = (socket) => {
      const entries = Object.values(this.webSockets)
      return entries.find((entry) => entry.socket === socket)
  }
}

export default new WebSocketService()
