<template>
  <div id="app">

    <!-- 页面主体 -->
    <router-view></router-view>
    
  </div>
</template>
<script>
import { Message } from 'element-ui';
import FingerprintJS from '@fingerprintjs/fingerprintjs' // 引入
import axios from 'axios';
import {set_lang} from '@/apis/api1.js'
export default {
  data() {
      return {
        fullscreenLoading: false,
        
      }
  },
  created: function () {
    // this.get_data();
    // console.log(123)
    // localStorage.removeItem('site_name')
    localStorage.setItem('is_loaded',false);
    localStorage.setItem('header_info',JSON.stringify({data:null}));
    this.getvisitorId();
    this.get_data();
  },
  methods:{
    // 获取设备或浏览器唯一标识
    async getvisitorId() {
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      const visitorId = result.visitorId
      localStorage.setItem('visitorId',visitorId);
      // console.log('唯一标识', visitorId);
    },
    test:function(){
      Message.error('失败测试')
      setTimeout(()=>{
         Message.success('成功测试')
      },500)
     
    },
    get_data(){
      var lan=JSON.parse(localStorage.getItem('lang_item'))||'spain'
      set_lang({lang:lan.id||'spain'}).then(res=>{
       
      })
  
    },
    openFullScreen() {
      this.fullscreenLoading = true;
      console.log(this.form)
      setTimeout(() => {
        this.fullscreenLoading = false;
      }, 2000);
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/css/icon.css';
@import '@/assets/css/style.css';
@import '@/assets/fonts/font.css';
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .logo{
    height:70px;
  }
  .el-button--primary{
    color:#fff!important;
  }
  .el-pager li,.el-pagination button{
    width:40px!important;
    height:40px!important;
    line-height:40px!important;
  }
  .pageHeader{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1899;
  }
  .el-message-box__wrapper{
    z-index: 2003!important;
  }
  .el-message{
    z-index: 2005!important;
  }
  .el-dialog__wrapper{
    z-index: 2002!important;
  }
  .v-modal{
    z-index: 1900!important;
  }
  .dplayer-setting{
    display:none;
  }
  .el-drawer__wrapper{
    z-index: 4000!important;
  }
  .search_box input{
    font-size: 16px;
  }
</style>
