// 西班牙语
export const pub = {
  accounts: "La cuenta",
  password: "La contraseña",
  code: "Código de verificación",
  out:'Salir del login',
  confirm_out:'Sí no cerrar sesión ahora',
  tip:'sugerencia',
  confirm:'determinar',
  users:'Usuario final',
  store:'Extremo de tienda',
  broker:'Broker extremo',
  user_center:'Centro personal',
  collection:'Colecciones y contrastes',
  price_line:'Ver curvas de precios regionales',
  login:'Inicio',
  register:'El registro',
  home:'La página',
  news:'Noticias',
  auction:'La subasta',
  jion:'La contratación',
  map:'Mapa',
  day:'d',
  hour:':',
  minutes:':',
  seconds:'',
  copy_s:'Replicar el éxito',
  init_d:'00:00:00'
}
export const seo={
  t1:"inicio",
  t2:"El registro",
  t3:"Recupera tu contraseña",
  t4:"Cambiar mi contraseña",
  t5:"Reclutamiento de talento",
  t6:"Reclutamiento de talento",
  t7:"La subasta",
  t8:"Noticias del sector",
  t9:"Listado de propiedades",
  t10:"Comparación de datos de colección",
  t11:"Cálculo de hipoteca",
  t12:"Mapa encuentra una habitación",
  t13:"Curva de precios",
  t14:"Colecciones y contrastes",
  t15:"Home para brokers",
  t16:"Login para corredores",
  t17:"Comunicación broker - cliente",
  t18:"Brokers - recordatorio de tareas",
  t19:"Brokers - itinerarios",
  t20:"Brokers - estadísticas de rendimiento",
  t21:"Contacto en línea",
  t22:"Brokers - detalles de anuncios en tiempo real",
  t23:"Broker - lista de clientes",
  t24:"Broker - detalles del cliente",
}
// broker_header
export const bh={
  out:'Salir del login',
  ssfy:'Listados en tiempo real',
  khgl:'Los clientes',
  rwtx:'La misión',
  yjtj:'Desempeño',
  jjrxx:'Intermediario',
}  
// En la primera página
export const u1={
  search_t:'Por favor, introduzca una palabra clave',
  search:'búsqueda',
  hot_search:'Top búsquedas populares',
  t1:'Buscar por mapa',
  t2:'Búsqueda de mapas, buscar listados en el mapa',
  t3:'buscar',
  t4:'Cálculo de hipoteca',
  t5:'Cálculo de préstamos hipotecarios. Calcular un préstamo para comprar una casa en la plataforma',
  t6:'Ir al cálculo',
  t7:'Préstamos de todos los bancos en un solo lugar',
  t8:'Precio de propiedad',
  t9:'El anticipo',
  t10:'Calcule su hipoteca',
  t11:'Las propiedades',
  t12:'Ver más sobre',
  t13:'La subasta',
  t14:'Precio actual',
  t15:'Hacer una oferta',
  t16:'Noticias e información',
  t17:'Ver los detalles',
  t18:'Por favor seleccione una categoría',
  t19:'determinar',
  t20:'Ver',
}  
export const u2={
  t1:'En la primera página',
  t2:'Noticias',
  t3:'Hora de lanzamiento',
  t4:'Navegar',
}  
export const u3={
  t1:'Ubicación del objeto',
  t2:'Precio inicial',
  t3:'Precio base garantizado',
  t4:'Margen de margen',
  t5:'Ya está registrado',
  t6:'retozando',
  t7:'el',
  t8:'Inscripción con depósito',
  t9:'Introduzca el precio',
  t10:'oferta',
  t11:'Introducción al tema',
  t12:'Presentación en video',
  t13:'Aviso de margen',
  t14:'Registro de ofertas',
  t15:'El estado',
  t16:'precio',
  t17:'licitador',
  t18:'tiempo',
  t19:'Todavía no hay ofertas registradas',
  t20:'Por favor, introduzca el precio de oferta correcto',
  t21:'Hacer una oferta',
}  
export const u4={
  t1:'Por favor introduzca una palabra clave',
  t2:'Estado de licitaciones',
  t3:'Precio de inicio',
  t4:'Hora de inicio',
  t5:'Hora de fin',
  t6:'pujar',
  t7:'Filtrar los resultados',
  t8:'total',
  t9:'datos',
  t10:'Precio actual',
  t11:'Hacer una oferta',
  t12:'Datos aún no disponibles',
  t13:'Todos los datos',
  t14:'No iniciado',
  t15:'En proceso',
  t16:'Ha terminado',
}
export const u5={
  t1:'Mi colección de',
  t2:'Precio total',
  t3:'tipográfico',
  t4:'contacto',
  t5:'Teléfono',
  t6:'La colección',
  t7:'Cancelar una colección',
  t8:'Añadir al contraste',
  t9:'Datos aún no disponibles',
  t10:'Número de teléfono',
  t11:'A las',
  t12:'Que el',
  t13:'Esta acción eliminará esta información. Si continúa',
  t14:'sugerencia',
  t15:'Esta acción marcará esta información. Si no continuar',
  t16:'Esta acción desmarcará esta información de favoritos. Si no continuar',
}
export const u6={
  t1:'Precio total',
  t2:'Fotos',
  t3:'Presentación',
  t4:'Plano',
  t5:'La ubicación',
  t6:'Elija el contraste',
  t7:'Número de teléfono',
  t8:'Reservar una visita',
  t9:'Datos aún no disponibles',
  t10:'Número de teléfono',
  t11:'A las',
  t12:'Que el',
  t13:'Seleccione una fecha',
  t14:'sugerencia',
  t15:'Selecciona la hora',
  t16:'Hola, estoy interesado en este apartamento y me gustaría visitarlo. CiZhi！',
  t17:'Seleccione la fecha de su cita',
  t18:'Por favor seleccione una hora para su cita',
  t19:'El contenido de la consulta no puede estar vacío',
  t20:'El correo electrónico no puede estar vacío',
  t21:'El número de contacto no puede estar vacío',
  t22:'Nombre de contacto no puede estar vacío',
}
export const u7={
  t1:'Recupera tu contraseña',
  t2:'Por favor ingrese su correo electrónico',
  t3:'Código de verificación',
  t4:'Acceso',
  t5:'Por favor introduzca el código',
  t6:'Por favor confirme la contraseña',
  t7:'Recupere inmediatamente',
  t8:'regreso',
  t9:'El correo electrónico no puede estar vacío',
  t10:'La contraseña no puede estar vacía',
  t11:'Repita la contraseña no puede estar vacía',
  t12:'Dos entradas inconsistentes',
  t13:'El captcha no puede estar vacío',
}
export const u8={
  t1:'En la primera página',
  t2:'Reclutamiento de talento',
  t3:'Detalles de reclutamiento',
  t4:'Condiciones de contratación',
  t5:'Descripción del trabajo',
  t6:'Por favor ingrese su nombre',
  t7:'Por favor ingrese un número de contacto',
  t8:'Por favor ingrese su email',
  t9:'Por favor ingrese una dirección de correspondencia',
  t10:'Por favor, introduzca el contenido explicativo',
  t11:'Presentación',
  t12:'El nombre no puede estar vacío',
  t13:'El número de contacto no puede estar vacío',
  t14:'El correo electrónico no puede estar vacío',
  t15:'La dirección de comunicación no puede estar vacía',
}
export const u9={
  t1:'En la primera página',
  t2:'Reclutamiento de talento',
  t3:'Solicitud',
}
export const u10={
  t1:'Cálculo de hipoteca',
  t2:'Precio de propiedad',
  t3:'El anticipo',
  t4:'Años del préstamo',
  t5:'en',
  t6:'Tipos de préstamos',
  t7:'Capital y interés equivalentes',
  t8:'Principal equivalente',
  t9:'Tipo de interés fijo',
  t10:'Tipo de interés variable',
  t11:'Impuestos y tasas',
  t12:'Monto del préstamo',
  t13:'Sus pagos mensuales',
  t14:'Su cuota mensual',
  t15:'Monto del préstamo',
  t16:'Porcentaje de financiación',
  t17:'Ver las mejores opciones de préstamo hoy',
}
export const u11={
  t1:'Por favor ingrese su email',
  t2:'Por favor introduzca el código',
  t3:'Recordar la contraseña',
  t4:'Olvidé mi contraseña',
  t5:'Iniciar sesión ahora',
  t6:'Iniciar sesión en',
  t7:'El registro',
  t8:'El correo electrónico no puede estar vacío',
  t9:'La contraseña no puede estar vacía',
}
export const u12={
  t1:'Mueve el mapa a tu área de interés y comienza a dibujar',
  t2:'Dibuja tu área',
  t3:'El símbolo de peso',
}
export const u13={
  t1:'En la primera página',
  t2:'Centro personal',
  t3:'La información básica',
  t4:'Cambiar contraseña de acceso',
  t5:'La información básica',
  t6:'Avatares',
  t7:'【Haga clic en la imagen para cambiar avatar】',
  t8:'apodo',
  t9:'Por favor ingrese un apodo',
  t10:'dirección',
  t11:'Tel.',
  t12:'Por favor ingrese su número de teléfono',
  t13:'Cambiar mi contraseña',
  t14:'La contraseña original',
  t15:'Por favor, introduzca la contraseña original',
  t16:'Una nueva contraseña',
  t17:'Por favor, introduzca una nueva contraseña',
  t18:'Confirme su contraseña',
  t19:'Por favor confirme la nueva contraseña',
  t20:'guardar',
  t21:'Subir imagen de avatar no debe exceder 4MB!',
  t22:'La contraseña original no puede estar vacía',
  t23:'Nueva contraseña no puede estar vacía',
  t24:'Confirmar contraseña no puede estar vacía',
  t25:'Dos entradas inconsistentes',
  t26:'El avatar del usuario no puede estar vacío',
  t27:'El nombre de usuario no puede estar vacío',
  t28:'El teléfono no puede estar vacío',
  t29:'El correo electrónico no puede estar vacío',
}
export const u14={
  t1:'Noticias del sector',
  t2:'Por favor introduzca una palabra clave',
  t3:'Datos aún no disponibles',
}
export const u15={
  t1:'Registro de usuarios',
  t2:'Por favor ingrese su correo electrónico',
  t3:'Código de verificación',
  t4:'Acceso',
  t5:'Por favor ingrese un apodo',
  t6:'Por favor introduzca el código',
  t7:'Por favor confirme la contraseña',
  t8:'El registro',
  t9:'Ya tiene una cuenta，De inmediato',
  t10:'inicio',
  t11:'El correo electrónico no puede estar vacío',
  t12:'El apodo no puede estar vacío',
  t13:'La contraseña no puede estar vacía',
  t14:'Repita la contraseña no puede estar vacía',
  t15:'Dos entradas inconsistentes',
  t16:'El código de certificado no puede estar vacío',
}
export const u16={
  t0:'Ver el mapa',
  t1:'Precio total',
  t2:'tipográfico',
  t3:'La colección',
  t4:'Cancelar una colección',
  t5:'contacto',
  t6:'eliminación',
  t7:'Reservar una visita',
  t8:'vídeo',
  t9:'Fotos',
  t10:'Presentación',
  t11:'Plano',
  t12:'La ubicación',
  t13:'Cálculo de hipoteca',
  t14:'Precio de propiedad',
  t15:'El anticipo',
  t16:'Años del préstamo',
  t17:'En',
  t18:'Tipos de préstamos',
  t19:'Capital y interés equivalentes',
  t20:'Principal equivalente',
  t21:'Los tipos de interés',
  t22:'Tipo de interés fijo',
  t23:'Tipo de interés variable',   
  t24:'Impuestos y tasas',   
  t25:'Monto del préstamo',   
  t26:'Sus pagos mensuales',   
  t27:'Consulta/mensaje',   
  t28:'Por favor ingrese el contenido',   
  t29:'Su correo electrónico',   
  t30:'Por favor ingrese su correo electrónico',   
  t31:'Para tu teléfono',   
  t32:'Por favor, introduzca su teléfono',   
  t33:'Su nombre y apellido',   
  t34:'Por favor ingrese su nombre',   
  t35:'contacto',   
  t36:'Teléfono',   
  t37:'Agente inmobiliario',   
  t38:'dirección',   
  t39:'Número de teléfono',   
  t40:'A las',   
  t41:'Que el',   
  t42:'Reservar una visita',   
  t43:'Seleccione una fecha',   
  t44:'Selecciona la hora',   
  t45:'Hola, estoy interesado en este apartamento y me gustaría visitarlo. CiZhi！',   
  t46:'Seleccione la fecha de su cita',   
  t47:'Por favor seleccione una hora para su cita',   
  t48:'El contenido de la consulta no puede estar vacío',   
  t49:'El correo electrónico no puede estar vacío',   
  t50:'El número de contacto no puede estar vacío',   
  t51:'Nombre de contacto no puede estar vacío',   
  t52:'Esta acción eliminará esta información. Si continúa?',   
  t53:'sugerencia',   
  t54:'Esta acción marcará esta información. Si no continuar?',   
  t55:'Esta acción desmarcará esta información de favoritos. Si no continuar?',   
}
export const u17={
  t1:'Por favor, introduzca una palabra clave',
  t2:'Filtrar por condiciones',
  t3:'precio',
  t4:'El límite inferior',
  t5:'El límite',
  t6:'La superficie',
  t7:'Tipo de residencia',
  t8:'casa',
  t9:'lavabo',
  t10:'Estado de vivienda',
  t11:'características',
  t12:'Las plantas',
  t13:'Características del terreno',
  t14:'Fecha de lanzamiento',
  t15:'Clasificación integrada',
  t16:'precio',
  t17:'Hora de lanzamiento',
  t18:'Precio total',
  t19:'tipográfico',
  t20:'contacto',
  t21:'Teléfono',
  t22:'eliminación',
  t23:'La colección',
  t24:'Cancelar una colección',
  t25:'Datos aún no disponibles',
  t26:'Número de teléfono',
  t27:'A las',
  t28:'Que el',
  t29:'Esta acción eliminará esta información. Si continúa?',
  t30:'sugerencia',
  t31:'Esta acción marcará esta información. Si no continuar?',
  t32:'Esta acción desmarcará esta información de favoritos. Si no continuar?',
  t33:'Más proyecciones en',
  t34:'Ocultar el filtro',
}

export const b1={
  t1:'La información básica',
  t2:'Avatares',
  t3:'【Haga clic en la imagen para cambiar avatar】',
  t4:'Los nombres',
  t5:'Por favor ingrese su nombre',
  t6:'apodo',
  t7:'Por favor ingrese un apodo',
  t8:'dirección',
  t9:'Por favor ingrese su email',
  t10:'Tel.',
  t11:'Por favor ingrese un teléfono',
  t12:'Guardar las modificaciones',
  t13:'El avatar del usuario no puede estar vacío',
  t14:'El nombre de usuario no puede estar vacío',
  t15:'El teléfono no puede estar vacío',
  t16:'El correo electrónico no puede estar vacío',
  t17:'Subir imagen de avatar no debe exceder 4MB!',
}
export const b2={
  t1:'Por favor ingrese',
  t2:'borrar',
  t3:'enviar',
  t4:'Por favor ingrese el contenido',
}
export const b3={
  t1:'Por favor ingrese',
  t2:'borrar',
  t3:'enviar',
  t4:'Por favor ingrese el contenido',
}
export const b4={
  t1:'Contactar al cliente',
  t2:'Historial de seguimiento de clientes',
  t3:'No hay registros de seguimiento',
  t4:'Rastrear el estado',
  t5:'Por favor seleccione un estado',
  t6:'Seguimiento de observaciones',
  t7:'Por favor ingrese el contenido',
  t8:'confirmación',
  t9:'Por favor seleccione el estado de seguimiento',
  t10:'El comentario no puede estar vacío',
}
export const b5={
  t1:'Por favor, introduzca el nombre del cliente/teléfono/correo electrónico',
  t2:'Por favor seleccione un estado',
  t3:'determinar',
  t4:'Comunicación con clientes',
  t5:'Ver los detalles',
  t6:'Datos aún no disponibles',
}
export const b6={
  t1:'Ver el mapa',
  t2:'Precio total',
  t3:'tipográfico',
  t4:'vídeo',
  t5:'Fotos',
  t6:'Presentación',
  t7:'Plano',
  t8:'La ubicación',
  t9:'Conocimiento de embarque',
  t10:'Correo electrónico',
  t11:'Por favor ingrese su correo electrónico',
  t12:'Precio bl por',
  t13:'Por favor ingrese el precio bl',
  t14:'comentar',
  t15:'Por favor ingrese una observación',
  t16:'determinar',
  t17:'Agente inmobiliario',
  t18:'dirección',
  t19:'El correo electrónico no puede estar vacío',
  t20:'El precio bl no puede estar vacío',
  t21:'El comentario no puede estar vacío',
}
export const b7={
  t1:'Por favor seleccione',
  t2:'confirmación',
  t3:'La portada',
  t4:'El nombre',
  t5:'precio',
  t6:'clasificación',
  t7:'La ubicación',
  t8:'navegar',
  t9:'El estado',
  t10:'tiempo',
  t11:'La operación',
  t12:'quienes',
  t13:'Conocimiento de embarque',
  t14:'Su correo electrónico',
  t15:'Por favor ingrese su correo electrónico',
  t16:'Precio bl por',
  t17:'Por favor ingrese el precio bl',
  t18:'comentar',
  t19:'Por favor ingrese el contenido',
  t20:'A las',
  t21:'Que el',
  t22:'El correo electrónico no puede estar vacío',
  t23:'El precio bl no puede estar vacío',
  t24:'El comentario no puede estar vacío',   
}
export const b8={
  t1:'Login para corredores',
  t2:'Por favor ingrese su email',
  t3:'Por favor ingrese su contraseña de acceso',
  t4:'Recordar la contraseña',
  t5:'Olvidé mi contraseña',
  t6:'Iniciar sesión ahora',
  t7:'Recupera tu contraseña',
  t8:'dirección',
  t9:'Por favor ingrese su email',
  t10:'Código de verificación',
  t11:'Por favor introduzca el captcha',
  t12:'La contraseña',
  t13:'Enviar código de verificación',
  t14:'Por favor, introduzca una nueva contraseña',
  t15:'Recupera tu contraseña ahora',
  t16:'Ir al login',
  t17:'El correo electrónico no puede estar vacío',
  t18:'La contraseña no puede estar vacía',
  t19:'El captcha no puede estar vacío',
}
export const b9={
  t1:'Por favor seleccione una categoría',
  t2:'determinar',
  t3:'Seleccione el mes'
}
// 页面
export const b10={
  t1:'Total',
  t2:'Inacabado',
  t3:'Ya terminado',
  t4:'Tasa de finalización',
  t5:'Por completar',
  t6:'En proceso',
  t7:'Ya terminado',
  t8:'Completar la tarea',
  t9:'Datos aún no disponibles',
  t10:'Está marcado como completado?',
  t11:'sugerencia',
  t12:'determinar',
  t13:'cancelación',
}

